import { customElement, inject } from 'aurelia-framework';
import { BooleanStatus }         from 'modules/administration/models/boolean-status';
import { FormAcbinDualListBox }  from 'resources/elements/aurelia-form/components/form-acbin-duallistbox';
import { AppContainer }          from 'resources/services/app-container';

@inject(AppContainer)
@customElement('form-select-box')
export class FormSelectBox extends FormAcbinDualListBox {

    /**
     * Subscribes observers
     */
    subscribeObservers() {
        super.subscribeObservers();

        this.observers.push(
            this.appContainer
                .bindingEngine
                .collectionObserver(this.model.element.options)
                .subscribe((newValue, oldValue) => {
                    this.refreshFromOptions(this.model.element.options);
                }),
        );

        this.observers.push(
            this.appContainer
                .bindingEngine
                .propertyObserver(this.model.element, 'options')
                .subscribe((newValue, oldValue) => {
                    this.refreshFromOptions(newValue);
                }),
        );
    }

    /**
     * Registers observers
     *
     * @param observer
     *
     * @returns {Number}
     */
    registerObserver(observer) {
        this.observers.push(
            this.appContainer
                .bindingEngine
                .collectionObserver(this.model.value)
                .subscribe((splices) => {
                    observer(splices);
                    this._updateSelectedOptions();
                }),
        );
    }

    /**
     * Refreshes from options
     *
     * @param options
     */
    refreshFromOptions(options) {
        this.backup_options = [];
        this.backup_options.splice(0, this.backup_options.length, ...options);

        this.model.element.options = options;

        if (this.model.element.options && typeof this.model.element.options === 'object' && !Array.isArray(this.model.element.options)) {
            this.backup_options        = this.transformOptionsObject(this.model.element.options);
            this.model.element.options = this.transformOptionsObject(this.model.element.options);
        }

        this.selectable_elements.splice(0, this.selectable_elements.length, ...this.model.element.options);
    }

    activate(model) {
        this.model          = model;
        this.modelElementId = this.model.element.id || this.model.element.key;

        this.customSettings = this.model.element.settings ? this.model.element.settings : {};

        // init some variables
        this.to_select_values   = [];
        this.to_deselect_values = [];

        this.selectable_elements   = [];
        this.deselectable_elements = [];

        this.selected_options = [];

        this.to_select_filter   = '';
        this.to_deselect_filter = '';

        // this instance in order to be possible to access it from outside
        this.model.element.instance = this;

        return this.fetchData().then((response) => {
            if (model.element.options && typeof model.element.options === 'object' && !Array.isArray(model.element.options)) {
                this.backup_options   = this.transformOptionsObject(model.element.options);
                model.element.options = this.transformOptionsObject(model.element.options);
            }
        });
    }

    createElement() {
        return this.simplePromise(() => {
            this.refresh_to_select   = true;
            this.refresh_to_deselect = true;

            this.to_select_filter_count   = 0;
            this.to_deselect_filter_count = 0;

            this.selectable_elements   = this.model.element.options;
            this.model.element.options = [...this.backup_options];
            this.deselectable_elements = [];
            this.selected_options      = [];

            if (!Array.isArray(this.model.value)) {
                this.model.value = [];
            } else {
                this._selectByArray(
                    this.model.element.options,
                    this.model.value,
                );
            }
        });
    }

    _selectByArray(select_options, selector_array) {
        select_options = this._flattenGroupedOptions(select_options);

        for (let i = 0; i < select_options.length; i++) {
            if (selector_array.includes(select_options[i].id)) {
                this.to_select_values.push(select_options[i]);
            }
        }
    }

    _flattenGroupedOptions(options) {
        return options.reduce((acc, element) => {
            if (element.options) {
                acc.push(...element.options);
            } else {
                acc.push(element);
            }
            return acc;
        }, []);
    }

    transformOptionsObject(options) {
        const optionsArray = [];

        if (options.exception) {
            return optionsArray;
        }

        if (Array.isArray(options)) {
            options.forEach(item => {
                if (item.options) {
                    // Item is a group
                    optionsArray.push({
                        label:   item.label,
                        options: item.options.map(option => {
                            return this.model.element.processResults
                                ? this.model.element.processResults(option)
                                : option;
                        }),
                    });
                } else {
                    optionsArray.push(
                        this.model.element.processResults
                            ? this.model.element.processResults(item)
                            : item,
                    );
                }
            });
        } else if (typeof options === 'object') {
            for (const [groupLabel, groupOptions] of Object.entries(options)) {
                optionsArray.push({
                    label:   groupLabel,
                    options: groupOptions.map(option => {
                        return this.model.element.processResults
                            ? this.model.element.processResults(option)
                            : option;
                    }),
                });
            }
        } else {
            console.warn('Unexpected options format in transformOptionsObject:', options);
        }

        return optionsArray;
    }

    isSelected(optionId) {
        return this._flattenGroupedOptions(this.to_select_values).some(option => option.id === optionId);
    }

    get isDisabled() {
        let attributes = this.model.element.attributes;

        if (typeof attributes === 'undefined' || attributes === null) {
            return false;
        }

        return attributes.disabled;
    }

    _updateSelectedOptions() {
        this.to_select_values = this.to_select_values.filter(option => {
            return this.model.value.includes(option.id);
        });
    }

    _updateSelection(event) {
        this.model.value.splice(0, this.model.value.length, ...this.to_select_values.map(option => option.id));
    }

    disableOption(option) {
        return typeof option.status_id !== 'undefined' && option.status_id === BooleanStatus.INACTIVE;
    }

    __includes(search, start) {
        'use strict';
        if (typeof start !== 'number') {
            start = 0;
        }

        if (start + search.length > this.length) {
            return false;
        } else {
            return this.indexOf(search, start) !== -1;
        }
    }

    refreshSelect() {
        this._refreshToSelect().then(() => {
            this.refresh_to_select   = true;
            this.selectable_elements = this.model.element.options
                .map((element) => {
                    if (element.options) {
                        const filteredOptions = element.options.filter((option) =>
                            this.toSelectListingMatcher(option),
                        );

                        return filteredOptions.length > 0
                            ? { ...element, options: filteredOptions }
                            : null;
                    }

                    return this.toSelectListingMatcher(element) ? element : null;
                })
                .filter(Boolean);

            this.to_select_filter_count = this.selectable_elements.length;
        });
    }

    selectAll() {
        this.to_select_values = this._flattenGroupedOptions(this.selectable_elements);
        this._updateSelection();
    }

    deselectAll() {
        this.to_select_values = [];
        this._updateSelection();
    }

    getSelectedOptions() {
        return this.to_select_values;
    }

    getSelectedOptionsIds() {
        return this.to_select_values.map(option => option.id);
    }

    findOptionById(id) {
        return this._flattenGroupedOptions(this.model.element.options).find(option => option.id === id);
    }
}
